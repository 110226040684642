.container.contact__container {
    width: 60%;
    display: grid;
    grid-template-columns: 30% 60%;
    gap: 10%;
}

.contact__options {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}

.contact__option {
    background: var(--color-bg-variant);
    padding: 1.2rem;
    border-radius: 1.2rem;
    text-align: center;
    border: 1px solid transparent;
    transition: var(--transition);
}

.contact__option:hover {
    background: transparent;
    border-radius: var(--color-primary-variant);
}

.contact__option-icon {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
}

.contact__option a {
    margin: 0.7rem;
    display: inline-block;
    font-size: 1rem;
}

.success-message {
    justify-content: center;
}

/* ========== FORM ========== */

form {
    display: flex;
    flex-direction: column;
    gap: 1.3rem;
}

input, textarea {
    width: 100%;
    padding: 1.5rem;
    border-radius: 2rem 0 5rem 0;
    background: transparent;
    border: 2px solid var(--color-primary-variant);
    resize: none;
    font-size: 1rem;
    color: var(--color-primary);
}

/* ===================== MEDIA QUERIES (MEDIUM DEVICES) ===================== */

@media screen and (max-width: 1024px) {
    .container.contact__container {
        grid-template-columns: 1fr;
        gap: 2rem
    }
    
}


/* ===================== MEDIA QUERIES (SMALL DEVICES) ===================== */

@media screen and (max-width: 600px) {
    .container.contact__container {
        width: var(--container-width-sm);
    }
}
