.portfolio__container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2.5rem;
    margin: 0 auto;
    max-width: 1200px;
    padding: 0 1.5rem;
    width: 100%;
}

.portfolio__item {
    background: var(--color-bg-variant);
    padding: 1.3rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);
}

.portfolio__title{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.portfolio__item:hover {
    border-color: var(--color-primary-variant);
    background-color: transparent;
}

.portfolio__item-image {
    border-radius: 1.5rem;
    overflow: hidden;
}

.portfolio__item h3 {
    margin: 1.2rem 0 2rem;
}

.portfolio__item-cta {
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;
}

/* ===================== MEDIA QUERIES (MEDIUM DEVICES) ===================== */

@media screen and (max-width: 1024px) {
    .portfolio__container {
        grid-template-columns: 1fr;
        gap: 1.5rem;
        padding: 0 1rem;
        width: 100%;
        margin: 0 auto;
        max-width: 100%;
        
    }
}


/* ===================== MEDIA QUERIES (SMALL DEVICES) ===================== */

@media screen and (max-width: 600px) {
    .portfolio__container {
        grid-template-columns: 1fr;
        gap: 1rem;
        padding: 0 1rem;
        width: 100%;
        margin: 0 auto;
        max-width: 100%;
    }
}